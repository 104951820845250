
import GeneralData from "@/modules/accountingAccounts/components/GeneralData.vue";
import useAccountingAccount from "@/modules/accountingAccounts/composables/useAccountingAccount";
import toastr from "toastr";
import {onMounted, ref} from "vue";
import router from "@/router";
export default {
    components: {GeneralData},
    props: ['id'],
    setup(props: { id: string }) {
        const {clearForm, accountingAccountForm, updateAccountingAccount, catalogs, getAccountingAccount} = useAccountingAccount()
        const sending = ref(false)
        const loading = ref(true)
        clearForm();
        onMounted(async () => {
            accountingAccountForm.value = await getAccountingAccount(props.id)
            loading.value = false
        })
        return {
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'accounting_accounts'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updateAccountingAccount(accountingAccountForm.value)
                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'accounting_accounts'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
